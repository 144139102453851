import React from "react";
import {useEffect, useState, useContext} from 'react';
import { Button, Form, Input, Select, Radio, Card, Image, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Home from "../views/homePage";
import AuthContext from "../context/AuthContext";

import './Home.style.scss'
import Navbar from "../components/Navbar";


const { Option } = Select;
const { Meta } = Card;
const { TextArea } = Input

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };


const HomePage = () => {
    const formRef = React.useRef(null)
    const {authTokens} = useContext(AuthContext);
    const [orientation, setOrientation] = useState('VERTICAL')
    const [size, setSize] = useState('MEDIUM')
    const [position, setPosition] = useState('LEFT')


    const card_style = {
      "VERTICAL": {
        "SMALL" : {
          "width": '100px',
          "height": '20px',
        },
        "MEDIUM" : {
          "width": '100px',
          "height": '40px',
        },
        "LARGE" : {
          "width": '100px',
          "height": '60px',
        },
      },
      "HORIZONTAL": {
        "SMALL" : {
          "width": '200px',
          "height": '100px',
        },
        "MEDIUM" : {
          "width": '150px',
          "height": '75px',
        },
        "LARGE" : {
          "width": '100px',
          "height": '25px',
        }, 
      }
    };


    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
    };

    const onOrientationChange = (value) => {
        setOrientation(value.target.value)
    };

    const onSizeChange = (value) => {
      setSize(value.target.value)
      
  };

    const onFinish = (values) => {
        const formData = new FormData();

        console.log('VALUE', values)
		formData.append('media_file', values.thumbnail[0].originFileObj);

        formData.append('card_layout', values.layout);
        formData.append('media_height', values.size);
        formData.append('img_alignment', values.position);
        
        formData.append('title', values.title);
        formData.append('description', values.description);

        const response = fetch(`${process.env.REACT_APP_API_URL}/rcs_api/send`, {
          method: "POST",
          headers: {
              Authorization: 'Bearer ' + authTokens.access
          },
          body: formData,
      });
    };

    const onReset = () => {
        console.log('On reset', formRef.current)
        formRef.current?.resetFields();
    };


    const onFill = () => {
        formRef.current?.setFieldsValue({
          msisdn: '+48692498898',
          orientation: 'vertical',
        });
      };


    useEffect(() => {
        console.log('USE EFFECT', orientation)
    }, [orientation])
  
    useEffect(() => {
      console.log('FormRef', orientation)
    }, [Form])


    return(
      <div>
          <Navbar/>
      </div>
    )
}

export default HomePage;